import React from "react"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import ButtonBack from "../components/ButtonBack"
import img1 from "../assets/img/trainings/tr1.jpg"
import img2 from "../assets/img/trainings/tr2.jpg"
import { Helmet } from "react-helmet"
import { SEO_DESCRIPTION } from "../utils"

const Title = styled.h1`
    text-align: center;
`

const SubTitle = styled.h2`
    text-align: center;
    font-size: 3rem;
    color: white;
    margin-bottom: 3rem;
`

const Description = styled.div`
    text-align: center;
    font-size: 1.5rem;
    color: white;
    margin-bottom: 3rem;
`

const Features = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    min-width div:not(:last-of-type) {
        margin-bottom: 2rem;
    }
`

const Feature = styled.div`
    text-align: center;
    padding: 1rem;
    background-image: linear-gradient(90deg, #377c9e 0%, #d889da 100%);
    color: white;
    border-radius: 5px;
    margin: 0.3rem;
`

const Bottom = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`

const Button = styled.div`
    text-align: center;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    background-color: #54c6ff;
    border-radius: 5px;
    height: 2rem;
    user-select: none;
    color: black;
    cursor: pointer;
    border: 3px solid white;
    &:hover {
        color: white;
        background-color: #377c9e;
    }
`

const Course = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    min-height: 30rem;
    background-size: cover;
    background-position: center;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    border-radius: 5px;
    margin-bottom: 2rem;
    @media (max-width: 740px) {
        height: auto;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    div:first-of-type {
        margin-right: 1rem;
    }
`

export default () => {
    const handleClick = url => {
        window.open(url)
        if (window.ym) {
            window.ym(516565, "reachGoal", "COURSE_CLICK")
        }
    }

    return (
        <Container>
            <Helmet>
                <title>Онлайн-курсы</title>
                <meta
                    name="keywords"
                    content="курсы по прическам, прически для себя, косички курс, прически курс"
                />
                <meta name="description" content={SEO_DESCRIPTION} />
            </Helmet>
            <Header />
            <Space />
            <Content>
                <TitleWrapper>
                    <ButtonBack backUrl="/">{"<"}</ButtonBack>
                    <Title>Онлайн-курсы курсы от Оли Нарыжной</Title>
                </TitleWrapper>
                {/* <Course
                    css={css`
                        background-image: url(${img1});
                    `}
                >
                    <SubTitle>
                        ОНЛАЙН-КУРС ПО СОЗДАНИЮ ПРИЧЁСОК ДЛЯ СЕБЯ
                    </SubTitle>
                    <Description>
                        Создавай себе новую причёску из кос и локонов за 10
                        минут каждый день
                    </Description>
                    <Features>
                        <Feature>3 вида плетения</Feature>
                        <Feature>2 техники локонов</Feature>
                        <Feature>10 причёсок</Feature>
                    </Features>
                    <Bottom>
                        <Button
                            onClick={() => {
                                handleClick(
                                    "https://allonlinepro.ru/business?utm_source=site&utm_medium=srch&utm_campaign=1"
                                )
                            }}
                        >
                            Узнать подробнее
                        </Button>
                    </Bottom>
                </Course> */}

                <Course
                    css={css`
                        background-image: url(${img2});
                    `}
                >
                    <SubTitle>
                        Научитесь всего за 10 дней плести ИДЕАЛЬНЫЕ КОСЫ ДЛЯ
                        ДОЧКИ и делать из них причёски.
                    </SubTitle>
                    <Description>
                        Ваша дочь будет самой очаровательной и аккуратной
                        девочкой в школе.
                    </Description>
                    <Features>
                        <Feature>До 12ти видеоуроков</Feature>
                        <Feature>Доступные техники</Feature>
                        <Feature>Обратная связь от меня</Feature>
                    </Features>
                    <Bottom>
                        <Button
                            onClick={() => {
                                handleClick(
                                    "https://allonlinepro.ru/level2?utm_source=site&utm_medium=srch&utm_campaign=1"
                                )
                            }}
                        >
                            Узнать подробнее
                        </Button>
                    </Bottom>
                </Course>
            </Content>
            <Space />
        </Container>
    )
}
